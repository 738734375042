import { defineStore } from "pinia";
import { useItemCollection, type ActionError } from "./useItemWithQueryCollection";
import type { EventModel } from "../client/models/eventModel";
import type PagedData from "~/utils/pagedData";
import type { MediaModel } from "~/client/models/mediaModel";
import dayjs from "dayjs";

export const useEventStore = defineStore("events", () => {
    const errorCallbackInvoker = (error: ActionError) => errorCallbacks.value.forEach(c => c(error))
    const errorCallbacks = ref<Set<(error: ActionError) => any>>(new Set())

    const collection = useItemCollection<EventModel>("/api/v1/events", dateSorter, i => Promise.resolve(i), errorCallbackInvoker)

    const fetchPastAsync = async (count: number) => {
        try {
            const data = (await $fetch("/api/v1/events", {
                query: {
                    startTimestamp: dayjs(Date.now()).subtract(1, "day").toDate().getTime(),
                    limit: count,
                }
            })) as PagedData<EventModel>
            if (!!data) {
                collection.pushItemsInLocalCollection(data.items)
                return data.items
            }
            return []
        } catch (error) {
            console.error(error)
            return []
        }
    }

    const fetchFutureAsync = async (count: number) => {
        try {
            const data = (await $fetch("/api/v1/events", {
                query: {
                    endTimestamp: Date.now(),
                    limit: count,
                    order: "asc"
                }
            })) as PagedData<EventModel>
            if (!!data) {
                collection.pushItemsInLocalCollection(data.items)
                return data.items
            }
            return []
        } catch (error) {
            console.error(error)
            return []
        }
    }

    const fetchMediasAsync = async (eventId: string) => {
        try {
            return (await $fetch("/api/v1/events/" + eventId + "/medias") as MediaModel[]) ?? []
        } catch (error) {
            console.error(error)
            return []
        }
    }

    const creator = useItemCreator<EventModel>("/api/v1/events", i => collection.pushItemsInLocalCollection([i]), errorCallbackInvoker)
    const updater = useItemUpdater<EventModel>("/api/v1/events/:id", i => collection.pushItemsInLocalCollection([i]), errorCallbackInvoker)
    const deleter = useItemDeleter("/api/v1/events/:id", i => collection.items.value.delete(i), errorCallbackInvoker)

    return {
        ...collection,
        errorCallbacks,
        fetchPastAsync,
        fetchFutureAsync,
        fetchMediasAsync,
        updateAsync: updater.updateItemAsync,
        deleteAsync: deleter.deleteItemsAsync,
        createAsync: creator.createItemAsync,
    }
})